import { useEffect } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useRouter } from 'next/router'

const Page404 = () => {

  const {push} = useRouter()
  return (
        <>
          <Container maxWidth={false} sx={{ height: "100vh", display: "flex", flexWrap: "wrap"}}>
            <Box sx={{justifyContent: "center", alignItems: "center", display: "flex", width: "100vw", flexWrap: "wrap", flexDirection: "column" }}>
                <Typography variant='h5'>Oups something went wrong</Typography>

                <Button  onClick={() => push("/")  }  variant='contained' sx={{width: "30%", marginTop: 3}}>Retry</Button>
            </Box>

          </Container>

        </>
  )
}

export default Page404
